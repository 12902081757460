var openModal, closeModal; 

jQuery(document).ready(function($){
    openModal = function ($el, data) {
        $el.addClass('open');

        //Autofill development logic
        var form = $el.find("form");

        if(form.hasClass("mailchimp")) {
            var form = $('#mc_embed_signup form');
            var q = $.query.load(form.attr('action')).set('DEV', data.dev);

            //form.attr('action', "https://bromford.us10.list-manage.com/subscribe/post"+q);
            form.attr('action', form.attr('action') + q);
        }

        if(form.hasClass("campaignmonitor")) {
            form.find(".dev-field").attr("value", data.dev);
            console.log(data.dev);
        }

        noScroll('on');
    }

    closeModal = function ($el) {
        $el.removeClass('open');
        noScroll('off');
    }

    //Loops each modal on the page
    $('.modal').each(function() {
        $(this).css('display', '')
    });

    //Checks for the trigger class
    $('.modal-trigger').each(function() {
        if($($(this).data('modal')).length) {
            $(this).click(function() {
                openModal($($(this).data('modal')), $(this).data());
            });
        }
    });

    //Checks for the buttons to close the modals
    $('.modal-close').click(function() {
        $modal = $(this).closest('.modal');
        closeModal($modal); 
    });
});