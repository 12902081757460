var noScroll;

jQuery(document).ready(function($){
	//No scroll
	noScroll = function (a) {
		function noScrollOn() {
			document.body.style.top = "-"+$(window).scrollTop()+"px";
			$("html").addClass('no-scroll');
		}
		
		function noScrollOff() {
			var top = document.body.style.top;
			$("html").removeClass('no-scroll');
			document.body.style.top = '';
			window.scrollTo(0, parseInt(top || '0') * -1);
		}
		
		if(a==null) {
			if($('html').hasClass('no-scroll')) {
				noScrollOff();
			} else {
				noScrollOn();
			}
		} else if (a=="on") {
			noScrollOn();
		} else if (a=="off" ) {
			noScrollOff();
		}
	}
});

window.onload = function() {
	$('body').addClass('ready');
}